import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";
import React, { FC, useCallback, useMemo, useState } from "react";

import * as styles from "./AboutPageValues.module.scss";
import playIcon from "assets/vector/play-button.svg";
import videoThumbnail from "assets/raster/about-values-video-thumbnail.png";
import getYoutubeEmbedId from "utils/getYoutubeEmbedId";
import IAboutPageValues from "./AboutPageValues.type";
import Parse from "react-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const AboutPageValues: FC<IAboutPageValues> = ({ data }) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const onPlayVideoClick = useCallback(() => setShowVideo(true), []);

  const iframeUrl = useMemo(() => {
    return `https://www.youtube.com/embed/${getYoutubeEmbedId(data.videoUrl)}?autoplay=1`;
  }, [data.videoUrl]);

  return (
    <DefaultSectionTemplate title="Czym się kierujemy">
      <div className={styles.container}>
        <div className={styles.content}>
          <p className="s-large">{Parse(data.text)}</p>
        </div>
        <div className={styles.videoWrapper}>
          {/* {showVideo ? (
            <div className={styles.iframeWrapper}>
              <iframe allow="autoplay" src={iframeUrl}></iframe>
            </div>
          ) : ( */}
            <div className={styles.thumbnail}>
              <GatsbyImage
                className={styles.placeholder}
                image={data.placeholderImage.localFile.childImageSharp.gatsbyImageData}
                alt={data.placeholderImage.altText}
              />
                {/* <button className={styles.playVideoBtn} onClick={onPlayVideoClick}>
                  <img src={playIcon} alt="Otwórz wideo" />
                </button> */}
            </div>
          {/* )} */}
        </div>
      </div>
    </DefaultSectionTemplate>
  );
};

export default AboutPageValues;
