import DefaultSectionTemplate from "components/templates/DefaultSectionTemplate";
import React, { FC } from "react";

import * as styles from "./AboutPageAbout.module.scss";

import worldMap from "assets/vector/world-bg.svg";
import cn from "utils/cn";
import IAboutPageAbout from "./AboutPageAbout.type";
import Parse from "react-html-parser";

const AboutPageAbout: FC<IAboutPageAbout> = ({ data }) => {
  return (
    <DefaultSectionTemplate title="O nas" className={styles.element} style={{ backgroundImage: `url(${worldMap})` }}>
      <div className={styles.container}>
        <div className={styles.clearText}>
          <p className="s-large">{Parse(data.upperText)}</p>
        </div>
        <div className={styles.blue}>
          <p className="c-white s-large">{Parse(data.lowerText)}</p>
        </div>
      </div>
    </DefaultSectionTemplate>
  );
};

export default AboutPageAbout;
