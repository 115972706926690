import React, { createContext, FC } from "react";

import Breadcrumbs from "components/molecules/Breadcrumbs";
import DefaultLayout from "components/templates/DefaultLayout";

import IAboutPage from "./AboutPage.type";
import AboutPageAbout from "./AboutPageAbout";
import AboutPageTeam from "./AboutPageTeam";
import AboutPageValues from "./AboutPageValues";

import content from "./AboutPage.content";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
export const AboutPageContext = createContext(content);

const AboutPage: FC<IAboutPage> = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(slug: { eq: "o-nas" }) {
            aboutUs {
              sekcjaCzymSieKierujemy {
                text
                videoUrl
                placeholderImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              sekcjaNaszZespol {
                member1 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
                member2 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
                member3 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
                member4 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
                member5 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
                member6 {
                  avatar {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    altText
                  }
                  name
                  position
                  linkedinProfileUrl
                }
              }
              sekcjaONas {
                upperText
                lowerText
              }
            }
          }
        }
      `}
      render={({
        wpPage: {
          aboutUs: { sekcjaCzymSieKierujemy: values, sekcjaNaszZespol: team, sekcjaONas: aboutUs },
        },
      }) => (
        <DefaultLayout>
          <Helmet
            title="Lendidea.com - O nas - możesz na nas polegać"
            meta={[
              {
                name: "description",
                content:
                  "Lendidea.com to koniec z biurokracją, setkami zmarnowanych godzin, bezskutecznego szukania kapitału, przeliczania niezrozumiałych tabel opłat i wróżenia całkowitego kosztu kapitału",
              },
            ]}
          />
          <Breadcrumbs items={[{ label: "Strona główna", href: "/" }, { label: "O nas" }]} />
          <AboutPageAbout data={aboutUs} />
          <AboutPageValues data={values} />
          {/* <AboutPageTeam data={team} /> */}
        </DefaultLayout>
      )}
    />
  );
};

export default AboutPage;
